import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const AboutUs = () => {
  const { t } = useTranslation();
  return (
    <>
      <section className="section-box-2 pt-100">
        <div className="container">
        <div className="banner-hero banner-image-single">
            <img
              src="/assets/imgs/page/provided/22.png"
              alt="Cible rh emploie"
              style={{ width: "100%", maxWidth: "500px", height: "auto" }}
            />
        </div>

          <div className="border-bottom pt-10 pb-10"></div>
        </div>
      </section>
      <br />
      <br />
      <section id="features" className="features">
        <div className="container">
          <div class="row gy-4 align-items-stretch justify-content-between features-item ">
            <div
              class="col-lg-6 d-flex align-items-center features-img-bg"
              data-aos="zoom-out"
            >
              <img
                src="/assets/imgs/page/provided/20.png"
                class="img-fluid"
                alt=""
              />
            </div>
            <div
              class="col-lg-5 d-flex justify-content-center flex-column"
              data-aos="fade-up"
            >
              <h4 className="text-danger" style={{fontWeight:"100"}}>{t("texts")}</h4>
              <br />
              <p className="fw-bold text-justify" style={{fontWeight:100}} dangerouslySetInnerHTML={{__html: t("textaboutf")}}></p>
              <br/>
              <p className="fw-bold text-justify">{t("textaboutT")}</p>
              <br/>
              <p className="fw-bold text-justify">{t("textaboutr")}</p>

              <br />
              <a
                href="https://www.ciblerh-emploi.com/"
                target="_blank"
                class="btn btn-get-started align-self-start"
              >
                <button className="btn btn-primary">
                  {t("textMoreDetails")}
                </button>
              </a>
            </div>
          </div>

          <br />
          <br />

          <div class="container section-title" data-aos="fade-up">
            <h4 className="text-danger mb-4" style={{fontWeight:"100"}} dangerouslySetInnerHTML={{__html:t("textv")}}></h4>
            <p className="fw-bold text-justify">
              {t("textvision")}
              <br />
              {t("textvision2")}
            </p>
          </div>
          <div class="row gy-4 align-items-center features-item">
            <div
              class="col-lg-5 order-2 order-lg-1"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <h4 className="text-danger mb-4" style={{fontWeight:"100"}} dangerouslySetInnerHTML={{__html:t("textm")}}></h4>
              <p className="fw-bold text-justify">
                {t("textmission1")} <br />
                {/* {t("textmission2")} <br />
                {t("textmission3")} */}
              </p>
            </div>
            <div
              class="col-lg-6 order-1 order-lg-2 d-flex align-items-center"
              data-aos="zoom-out"
              data-aos-delay="100"
            >
              <div class="image-stack">
                <img
                  src="/assets/imgs/page/provided/21.png"
                  alt=""
                  class="stack-front"
                />
                <img
                  src="/assets/imgs/page/provided/13.png"
                  alt=""
                  class="stack-back"
                />
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <div className="row">{""}</div>
      </section>
    </>
  );
};

export default AboutUs;
